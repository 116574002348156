import jwt_decode from "jwt-decode";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { env_url } from "../../../environments/env";
import HttpServices from "../../../environments/environment_urls";
import { getCurrentUTCTime } from "../../../functions/common";
import {
  excludeSupplierRoutes,
  getURLFromURL,
} from "../../../functions/url-modifications";
import Footer from "../footer";
import Header from "../header";
import Leftsidebar from "../leftsidebar";

const LayoutHOC = (props) => {
  const history = useHistory();
  const currentPath = window.location.pathname;

  const [toggled, setToggled] = useState(false);
  const [currentTime, setCurrentTime] = useState(null);
  const [sessionTime, setSessionTime] = useState(null);
  const [startSession, setStartSession] = useState(false);
  const [renderContent, setRenderContent] = useState("");
  const prevChildrenRef = useRef();

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const fetchCurrentTime = useCallback(async () => {
    // try {
    //   const response = await fetch("https://worldtimeapi.org/api/timezone/utc");
    //   const data = await response.json();
    //   setCurrentTime(data.utc_datetime);
    // } catch (error) {
    //   const data = getCurrentUTCTime();
    //   setCurrentTime(data);
    // }
    const data = getCurrentUTCTime();
    setCurrentTime(data);
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token !== null || token !== "" || token !== undefined) {
      const decoded = jwt_decode(token);
      const expirationDate = new Date(decoded.exp * 1000);
      setSessionTime(expirationDate.toISOString());
      fetchCurrentTime();
      setStartSession(true);
    }
  }, [fetchCurrentTime]);

  useEffect(() => {
    let interval;
    let timeInterval;
    let showAlert = true;
    if (startSession && currentTime) {
      interval = setInterval(() => {
        if (sessionTime < currentTime && showAlert === true) {
          showAlert = false;
          alert(
            "Your login session is over!! Please Login again to continue the application.."
          );
          setTimeout(() => {
            sessionStorage.clear();
            window.location.href = "/signin";
          }, 1200);
        } else if (showAlert === false) {
          clearInterval(interval);
        }
      }, 2000);
      const token = sessionStorage.getItem("token");
      if (
        (showAlert === true && token !== null) ||
        token !== "" ||
        token !== undefined
      ) {
        timeInterval = setInterval(fetchCurrentTime, 20000);
      } else {
        clearInterval(timeInterval);
      }
    }
    return () => {
      clearInterval(interval);
      clearInterval(timeInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startSession, currentTime]);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    let access_granted = false;
    if (token !== "InValid User") {
      if (
        excludeSupplierRoutes(window.location.pathname?.replace("/", "")) ===
        false
      ) {
        const form = new FormData();
        form.append(
          "functionUrl",
          getURLFromURL(window.location.pathname?.replace("/", ""))
        );
        HttpServices.postData(env_url.getRoleAuthorize, form)
          .then((resp) => {
            if (resp.data) {
              access_granted = resp.data;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
    setRenderContent(access_granted);
  }, []);

  useEffect(() => {
    if (history.location.search) {
      const urlParams = new URLSearchParams(history.location.search);
      const id = urlParams.get("id");
      const taskId = urlParams.get("taskId");
      history.replace({
        pathname: history.location.pathname,
        state: { id: id || "", taskId: taskId || "" },
      });
    }
  }, [history]);

  useEffect(() => {
    if (prevChildrenRef.current !== props.children) {
      setTimeout(() => {
        const element = document.getElementsByClassName("content-block");
        if (element) {
          element[0].scrollTo(0, 0);
        }
      }, 600);
    }
    prevChildrenRef.current = props.children;
  }, [props.children]);

  return (
    <>
      {currentPath !== "/" && (
        <section className="content-block">
          {props.pageLevel === 2 ? (
            <>
              <Leftsidebar
                supplier
                completedProgress={100}
                completedCompanyDetails
                completedScopeofSupply
                completedContactReference
                completedBanking
                completedCertificates
                disableBlock={props.disableBlock}
                handleToggleSidebar={handleToggleSidebar}
                toggled={toggled}
              />
              <div className="container-fluid block-pads">
                <Header
                  titleText={props.titleText}
                  linkToBack={props.linkToBack}
                  supplier
                  disableBlock={props.disableBlock}
                  handleToggleSidebar={handleToggleSidebar}
                />
                {props.children}
                <Footer />
              </div>
            </>
          ) : (
            <>
              <Leftsidebar
                pageLevel={1}
                showMenu
                disableBlock={props.disableBlock}
                handleToggleSidebar={handleToggleSidebar}
                toggled={toggled}
              />
              <div className="container-fluid block-pads">
                <Header
                  titleText={props.titleText}
                  linkToBack={props.linkToBack}
                  ref={props.headerRef}
                  disableBlock={props.disableBlock}
                  handleToggleSidebar={handleToggleSidebar}
                />
                {renderContent !== "" ? (
                  props.children
                ) : (
                  <div className="block-center">
                    <div className="col-12 text-center">
                      <div className="loader"></div>
                    </div>
                  </div>
                )}
                <Footer />
              </div>
            </>
          )}
        </section>
      )}
    </>
  );
};

export default LayoutHOC;
