import React, { Component } from "react";
import { Link } from "react-router-dom";
import Icon from "../../../components/icon";
import TermsConditions from "../../../components/terms-conditions";
import { convertDateFormat } from "../../../functions/common";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastLogin: "",
    };
  }
  componentDidMount() {
    setInterval(() => {
      const loggedIn = sessionStorage.getItem("lastLogin");
      this.setState({ lastLogin: loggedIn });
    }, 5000);
  }
  render() {
    return (
      <footer>
        <div className="footer-block">
          <div className="copyright">
            Copyright&copy;{new Date().getFullYear()} Prokraya Tech Private
            Limited, All rights reserved. |{" "}
            <Link to="/privacy-policy">Privacy Policy</Link> |{" "}
            <TermsConditions type="Footer" class="redirectionLink" />
          </div>
          <div className="login-block">
            Last Login:{" "}
            <span style={{ color: "#206ecc", fontWeight: "bold" }}>
              {this.state.lastLogin
                ? convertDateFormat(this.state.lastLogin, `DD-MM-YYYY hh:mm a`)
                : "N/A"}
            </span>
          </div>
          <div className="help-block">
            <Icon iconName="help mr-1" iconSize={20} />
            Help
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer; 
