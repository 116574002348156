import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { env_url } from "../environments/env";
import HttpServices from "../environments/environment_urls";
import { calculateRemainingDays } from "../functions/common";
import LayoutHOC from "../modules/common/layout-hoc/layout-hoc";
import { PrivateRoute } from "./PrivateRoute";

const headerRef = React.createRef();
const updateHeader = () => {
  setTimeout(() => {
    headerRef.current.notificationData();
    headerRef.current.getALLPendingTasks();
  }, 2000);
};

const HeaderLayout = ({ component: Component, ...rest }) => {
  const [trailExpired, setTrailExpired] = useState(false);

  useEffect(() => {
    const url = env_url.getMySubscriptionInfo;
    HttpServices.getData(url).then((response) => {
      if (response.data) {
        let planInfo = response.data.subscriptions[0];
        if (planInfo.planId === 105) {
          calculateRemainingDays(planInfo.startDate, planInfo.expiryDate) <= 0
            ? setTrailExpired(true)
            : setTrailExpired(false);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [rest.location.pathname]);

  const trailExpiredRoutes = [
    "/end-trail",
    "/my-subscription",
    "/checkout",
    "/payment-response",
  ];

  return (
    <PrivateRoute>
      {trailExpired ? (
        trailExpiredRoutes.includes(rest.location.pathname) ? (
          <Route
            exact
            path={trailExpiredRoutes}
            render={(props) => (
              <LayoutHOC
                titleText={rest.title}
                linkToBack={rest.linkToBack}
                headerRef={headerRef}
                disableBlock={true}
              >
                <Component {...props} updateHeader={updateHeader} />
              </LayoutHOC>
            )}
          />
        ) : (
          <Redirect to="/end-trail" />
        )
      ) : (
        <>
          {rest.location.pathname !== "/end-trail" ? (
            <Route
              {...rest}
              render={(props) => (
                <LayoutHOC
                  titleText={rest.title}
                  linkToBack={rest.linkToBack}
                  headerRef={headerRef}
                >
                  <Component {...props} updateHeader={updateHeader} />
                </LayoutHOC>
              )}
            />
          ) : (
            <Redirect to="/dashboard" />
          )}
        </>
      )}
    </PrivateRoute>
  );
};

export default HeaderLayout;
