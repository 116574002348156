import React, { Component } from 'react';
import Icon from '../icon';
import ChatLogo from '../../assets/images/chat-logo.jpg';
import Input from '../input';
import { Collapse } from 'reactstrap';
import usericon from '../../assets/images/user.jpg';
import { Link } from 'react-router-dom';

//Chat Roles
const ROBOT = 'ROBOT';
const USER = 'USER';

class ChatBot extends Component {
  constructor(props) {
    super(props)

    this.state = {
      languageOpts: [
        { value: 'En', label: 'En' },
        { value: 'Ar', label: 'Ar' }
      ],
      chatData: [
        {
          role: ROBOT,
          msg: "Hi! Enay, Good Evening.How can I help you?",
          time: '4:41 pm'
        },
        {
          role: USER,
          msg: "Need Some Information Regarding the Updates on GRN Lines",
          time: '4:41 pm'
        },
        {
          role: ROBOT,
          msg: "Sure, Please provide PO Number or PO ID",
          time: '4:41 pm'
        },
        {
          role: USER,
          msg: "# PO786879",
          time: '4:41 pm'
        },
        {
          role: ROBOT,
          msg: "Here are the details of GRN Lines for PO #PO786879",
          time: '4:41 pm',
          tableData: {
            infoData: [
              {
                label: 'Item Name',
                value: 'Lorem Ipsum - Lorem Ipsum Description'
              },
              {
                label: 'Received Date',
                value: '22 Nov, 2020'
              }
            ],
            cols: ["Received Qty", "Pending Qty"],
            rows: [["10/20", "10/20"], ["10/20", "10/20"]],
            link: {
              val: '#',
              text: 'View PO Details'
            }
          }
        }
      ],
      isOpen: false,
      showContent: false,
      showTypeAnimation: false
    }
    this.chatIconRef = React.createRef()
    this.chatRef = React.createRef()
    this.bodyRef = React.createRef()
  }

  changeLanguage = val => console.log("Selected Language:-", val)

  toggleChat = () => {
    this.chatIconRef.current.classList.toggle("d-none");
    this.chatRef.current.classList.toggle("d-none");
  }

  minimizeChat = () => {
    this.chatRef.current.classList.toggle("h-10");
  }

  inputAction = (event, action = null) => {
    const elements = document.querySelectorAll('.input-group-text');
    elements.forEach(
      (c) => c.classList[event.currentTarget === c ? 'toggle' : 'remove']('active')
    )
    switch (action) {
      case 'CHOOSE':
        this.setState({ isOpen: !this.state.isOpen })
        break;
      default:
        break;
    }
  }

  closeChoose = () => {
    const elements = document.querySelectorAll('.input-group-text');
    elements.forEach(
      c => c.classList.remove('active')
    )
    this.setState({ isOpen: !this.state.isOpen })
  }

  onScroll = () => {
    let scrollTop = this.bodyRef.current.scrollTop;
    if (scrollTop > 300) {
      setTimeout(() => this.setState({ showContent: true }), 1000)
    }
  }

  inputChangeHandler = () => {
    this.setState({ showTypeAnimation: !this.state.showTypeAnimation }, () => {
      this.bodyRef.current.scrollTop = this.bodyRef.current.scrollHeight
    })
  }

  getChatHTMl = (chatData, index) => {
    return (
      <div key={"msg" + index} className={"d-flex mb-3" + (chatData.role === USER ? " justify-content-end" : "")}>
        {chatData.role === ROBOT &&
          <div className="circle circle-extra-small circle-dodger-blue mr-2">
            <Icon iconName="chat-bot" iconSize={18} />
          </div>
        }
        <div className="mw-75">
          <div className={"container w-100" + (chatData.role === USER ? " container-light" : " container-dark")}>
            <p className={chatData.role === USER ? "text-right" : ""}>{chatData.msg}</p>
          </div>
          {chatData.tableData &&
            <div className="details mt-20 bg-zircon">
              {
                chatData.tableData.infoData.map((data, index) => {
                  return (
                    <React.Fragment key={"info" + index}>
                      <span>{data.label} :</span>
                      <span>{data.value}</span>
                    </React.Fragment>
                  )
                })
              }
              <table className="mt-30 w-100 mb-3">
                <thead>
                  <tr>
                    {
                      chatData.tableData.cols.map((col, index) => <th key={"th" + index}>{col}</th>)
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    chatData.tableData.rows.map((row, index) => {
                      return (
                        <tr key={"row" + index}>
                          {
                            row.map((data, index) => <td key={"data" + index}>{data}</td>)
                          }
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
              {chatData.tableData.link &&
                <Link to={chatData.tableData.link.val} className="link">{chatData.tableData.link.text}</Link>
              }
            </div>
          }
          <span className="time">{chatData.time}</span>
        </div>
        {chatData.role === USER &&
          <img src={usericon} className="ml-2" alt="user" />
        }
      </div>
    )
  }

  render() {
    const state = this.state
    return (
      <>
        {/* Chat Bot icon */}
        <div className="chat-bot-icon" onClick={this.toggleChat} ref={this.chatIconRef}>
          <Icon iconName="chat-bot" iconSize={40} />
        </div>
        {/* Chat Bot icon */}
        {/* Chat Bot Container Start */}
        <div className="chat-bot d-none" ref={this.chatRef}>
          <div className="chat-bot-head d-flex justify-content-between">
            <img src={ChatLogo} alt="" />
            <div className="d-flex align-items-center">
              <Input type="select"
                options={state.languageOpts}
                getChanges={this.changeLanguage}
                isSearchable={false}
                placeholderText="Choose Language"
                defaultValue={state.languageOpts[0]}
                class="mb-0 mr-30"
              />
              <span className="icon-box d-flex justify-content-center align-items-center" onClick={this.minimizeChat}><span className="minimize"></span></span>
              <span className="icon-box d-flex justify-content-center align-items-center ml-2" onClick={this.toggleChat} ><Icon iconName="close" iconSize={18} /></span>
            </div>
          </div>
          <div className="chat-bot-body h-100" onScroll={this.onScroll} ref={this.bodyRef}>
            {state.chatData && state.chatData.length > 0 &&
              state.chatData.map((chatData, index) => this.getChatHTMl(chatData, index))
            }
            {!state.showContent &&
              <div className="text-center mb-4">
                <div className="loader"></div>
              </div>
            }
            {state.showContent &&
              <div className="d-flex mb-3">
                <div className="circle circle-extra-small circle-dodger-blue mr-2">
                  <Icon iconName="chat-bot" iconSize={18} />
                </div>
                <div className="mw-75">
                  <div className="container container-dark w-100">
                    <p>
                      Sure, Please provide PO Number or PO ID
                  </p>
                  </div>
                  <span className="time">4:41 pm</span>
                </div>
              </div>
            }
            {/* Type Animation End*/}
            {state.showTypeAnimation &&
              <div className="mb-3 text-right">
                <span className="spinme-right">
                  <div class="spinner">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                  </div>
                </span>
              </div>
            }
            {/* Type Animation End*/}
          </div>
          <Collapse isOpen={state.isOpen} className="chat-options">
            <ul className="chat-options-list">
              <li className="d-flex align-items-center">
                <div className="circle circle-extra-small circle-foam mr-2">
                  <Icon iconName="clear-chat" iconSize={20} />
                </div>
                <span onClick={this.closeChoose}>Clear Chat</span>
              </li>
              <li className="d-flex align-items-center">
                <div className="circle circle-extra-small circle-colonial-white mr-2">
                  <Icon iconName="admin-contact" iconSize={20} />
                </div>
                <span onClick={this.closeChoose}>Contact Your Admin</span>
              </li>
              <li className="d-flex align-items-center">
                <div className="circle circle-extra-small circle-fair-pink mr-2">
                  <Icon iconName="enqquires" iconSize={20} />
                </div>
                <span onClick={this.closeChoose}>Prokraya Support</span>
              </li>
            </ul>
          </Collapse>
          <div className="chat-bot-foot">
            <div className="input-group">
              <input type="text" className="form-control" onFocus={this.inputChangeHandler} onBlur={this.inputChangeHandler} placeholder="Type Here…" />
              <div className="input-group-append">
                <span className="input-group-text" onClick={this.inputAction}>
                  <Icon iconName="chat-send" iconSize={24} />
                </span>
                <span className="input-group-text" onClick={this.inputAction}>
                  <Icon iconName="chat-speaker" iconSize={24} />
                </span>
                <span className="input-group-text" onClick={event => this.inputAction(event, 'CHOOSE')}>
                  <Icon iconName="chat-hamburger" iconSize={24} />
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* Chat Bot Container End*/}
      </>
    );
  }
}

export default ChatBot;
