import React from "react";
import { Link } from "react-router-dom";
// import { InputGroup, InputGroupButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";

import { env } from "../../environments/env";
import {
  convertAmount,
  extrctCurrencySymbol,
  lowercaseWord,
} from "../../functions/common";
import Customtooltip from "../customtooltip";
import Icon from "../icon";
import TermsConditions from "../terms-conditions";

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <div className="label-value">
        {props.data.sublabel}{" "}
        <div className="label-subtext">{props.data.sublabel}</div>
      </div>
    </components.Option>
  );
};

const BudgetOption = (props) => {
  return (
    <components.Option {...props}>
      <div className="label-value">{props.data.label}</div>
      <small className="label-subtext" style={{ color: "red" }}>
        Available Budget -{" "}
        {props
          ? `${extrctCurrencySymbol(props.data.currency)} ${convertAmount(
              props.data.amount,
              props.data.currency
            )}`
          : `${extrctCurrencySymbol(props.data.currency)} 0.00`}
      </small>
    </components.Option>
  );
};
const isMultiCheck = (props) => {
  return (
    <components.Option {...props}>
      <div className="d-flex align-items-center">
        <input
          type="checkbox"
          className="mr-2"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <div className="label-value ml-3">{props.data.label}</div>
      </div>
    </components.Option>
  );
};
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon iconName="search" iconSize={20} />
    </components.DropdownIndicator>
  );
};
const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      {props.selectProps.value ? (
        <Icon
          iconName={props.selectProps.value.iconName}
          className="mr-2"
          iconSize={20}
        />
      ) : (
        ""
      )}
      {children}
    </components.Control>
  );
};
const CreateTemplateOpts = (props) => {
  return (
    <components.Option {...props}>
      <div className="d-flex align-items-center">
        <Icon iconName={props.data.iconName} className="mr-2" iconSize={20} />
        <div className="label-subtext">{props.data.label}</div>
      </div>
    </components.Option>
  );
};
const UserControl = ({ value, label, imageUrl, errorImg }) => (
  <div className="d-inline align-items-center">
    <img
      src={imageUrl ? `data:image/png;base64,${imageUrl}` : errorImg}
      className="user-icon-label"
      alt="user-img"
    />{" "}
    {label}
  </div>
);
const UserMap = (props) => {
  return (
    <components.Option {...props}>
      <div className="d-flex align-items-center">
        <img
          src={
            props.data.imageUrl
              ? `data:image/png;base64,${props.data.imageUrl}`
              : props.data.errorImg
          }
          className="user-icon-label"
          alt="user-img"
        />{" "}
        {props.data.label}
      </div>
    </components.Option>
  );
};
const filterPassedTime = (time, openTime) => {
  // const currentDate = new Date();
  // const selectedDate = new Date(time);
  const selectedTime = new Date(time);
  const selectedOpenTime = new Date(openTime);
  return selectedTime.getTime() > selectedOpenTime.getTime();
};
const Input = (props) => {
  const calendarRef = React.createRef();
  const handleCalendarIconClick = () => {
    if (calendarRef.current) {
      calendarRef.current.setOpen(true);
    }
  };
  const SelectTag = props.tagCreatable ? CreatableSelect : Select;
  const InputTypeSwitch = () => {
    switch (props.type) {
      case "checkbox":
        return (
          <label
            className={
              "form-group-checkbox " + (props.class ? props.class : "")
            }
          >
            {props.formLabel}{" "}
            {props.linkText && props.link === "/tnc-apply" ? (
              <TermsConditions type={props.termsType} />
            ) : props.linkText ? (
              <Link target="_blank" to={props.link}>
                {props.linkText}
              </Link>
            ) : (
              <></>
            )}
            <input
              id={props.id}
              type={props.type}
              name={props.name}
              onChange={
                props.onChange ? (event) => props.onChange(event) : null
              }
              defaultChecked={props.defaultValue}
              checked={props.checked}
              disabled={props.disabled}
            />
            <span className="checkmark">{props.checkmarkValue}</span>
          </label>
        );
      case "select":
        return (
          <div
            className={"form-group-select " + (props.class ? props.class : "")}
            style={props.style}
          >
            <SelectTag
              id={props.id}
              className={
                "SelectWidget " +
                (props.multiple ? "multiple" : "") +
                (props.isinvalid ? " invalid" : "")
              }
              classNamePrefix="select"
              options={props.options}
              blur={props.blur}
              value={props.value}
              onChange={(selectedvalue) => props.getChanges(selectedvalue)}
              onInputChange={
                props.searchInput
                  ? (selectedvalue) => props.getInputChanges(selectedvalue)
                  : ""
              }
              onBlur={props.onBlur ? props.onBlur : null}
              isDisabled={props.isDisabled}
              defaultValue={props.defaultValue}
              isSearchable={props.isSearchable}
              isClearable={props.clearValue ? true : false}
              isMulti={props.multiple}
              placeholder={props.placeholderText}
              onClick={props.onClick}
              closeMenuOnSelect={props.multiSelectDropdown ? false : true}
              hideSelectedOptions={props.multiSelectDropdown ? true : false}
              allowSelectAll={props.multiSelectDropdown ? true : false}
              components={
                (props.haslabel && { Option: CustomOption }) ||
                (props.hasbudget && { Option: BudgetOption }) ||
                (props.class === "select-search" &&
                  !props.imageLabel && { DropdownIndicator }) ||
                (props.createTemplate && {
                  Option: CreateTemplateOpts,
                  Control,
                }) ||
                (props.imageLabel && { Option: UserMap }) ||
                (props.multiSelectDropdown && { Option: isMultiCheck }) ||
                props?.components
              }
              formatOptionLabel={props.imageLabel && UserControl}
              noOptionsMessage={() => props.hasbudget && "No Budgets Found"}
              menuPortalTarget={props?.menuPortalTarget}
              styles={props?.styles}
            />
            {props.formLabel && (
              <label htmlFor={props.formLabel}>
                {props.formLabel} {props.required && <span>*</span>}
              </label>
            )}
            <div
              className={
                "d-flex " +
                ((props.isinvalid ? "justify-content-between" : "") ||
                  (!props.isinvalid ? "justify-content-end" : ""))
              }
            >
              {props.isinvalid && (
                <div className="invalid-msg">{props.invalidmsg}</div>
              )}{" "}
            </div>
          </div>
        );
      case "radio":
        return (
          <label
            className={"form-group-radio " + (props.class ? props.class : "")}
            title={props.title}
          >
            {props.formLabel}
            <input
              id={props.id}
              type={props.type}
              name={props.name}
              onChange={props.onChange}
              defaultChecked={props.defaultValue}
              disabled={props.disabled}
              checked={props.checked}
            />
            <span className="checkmark"></span>
          </label>
        );
      case "file":
        return (
          <div
            className={"form-group-input " + (props.class ? props.class : "")}
          >
            <label className={props.className} htmlFor={props.formLabel}>
              {props.formLabel} {props.required && <span>*</span>}
              <input
                id={props.id}
                type={props.type}
                name={props.name}
                onChange={props.onChange}
                accept={props.accept}
                disabled={props.disabled}
                multiple={props.multiple}
                onClick={props.onClick}
              />
            </label>
            {props.labelHint ? (
              <span
                className={
                  "file-hint " + (props.hintClass ? props.hintClass : "")
                }
              >
                {props.labelHint}
              </span>
            ) : (
              ""
            )}
          </div>
        );
      case "textarea":
        return (
          <div
            className={"form-group-input " + (props.class ? props.class : "")}
          >
            <textarea
              id={props.id}
              ref={props.refs && props.refs}
              name={props.name}
              defaultValue={props.defaultValue}
              className={props.className + (props.isinvalid ? " invalid" : "")}
              onChange={props.onChange}
              onKeyPress={props.onKeyPress}
              placeholder={props.placeholder}
              autoFocus={props.autoFocus}
              onBlur={props.onBlur}
              onSubmit={props.onSubmit}
              rows={props.rows}
              maxLength={props.emailLength ? "5000" : "250"}
              disabled={props.disabled}
              readOnly={props.readonly}
              onKeyDown={props.onKeyDown}
              autoComplete="off"
            />
            {props.formLabel && (
              <label htmlFor={props.formLabel}>
                {props.formLabel} {props.required && <span>*</span>}{" "}
                {props.formSubLabel && (
                  <span className="sublabel">{props.formSubLabel}</span>
                )}{" "}
                {props.info && <Customtooltip item={props.infoData} />}{" "}
                {props.labelright && (
                  <Link className="label-right" to={props.labelto}>
                    {props.labeltoname}
                  </Link>
                )}
              </label>
            )}
            <div
              className={
                "d-flex " +
                ((props.isinvalid ? "justify-content-between" : "") ||
                  (!props.isinvalid ? "justify-content-end" : ""))
              }
            >
              {props.isinvalid && (
                <div className="invalid-msg">{props.invalidmsg}</div>
              )}{" "}
            </div>
          </div>
        );
      case "toggle-switch":
        return (
          <div
            className={"form-group-switch " + (props.class ? props.class : "")}
            title={props.tooltip ? props.tooltip : ""}
          >
            <label className="switch" id={props.id}>
              <input
                type="checkbox"
                disabled={props.disable}
                onChange={
                  props.onChange ? (event) => props.onChange(event) : null
                }
                defaultChecked={props.switchVal}
              />
              <span
                className={"slider-round " + (props.switchVal ? "checked" : "")}
              />
            </label>
            {props.labelValue && (
              <span className="status">{props.labelValue}</span>
            )}
          </div>
        );
      case "date":
        return (
          <div
            className={"form-group-date " + (props.class ? props.class : "")}
          >
            {props.formLabel && (
              <label htmlFor={props.formLabel}>
                {props.formLabel} {props.required && <span>*</span>}
              </label>
            )}
            <DatePicker
              className={props.className + (props.isinvalid ? " invalid" : "")}
              calendarClassName={props.calendarClassName}
              selected={props.value ? props.value : ""}
              onChange={props.onChange}
              wrapperClassName="datePicker"
              placeholderText={props.placeholder}
              dateFormat={props.format}
              showYearDropdown
              showMonthDropdown
              closeOnScroll={true}
              showTimeSelect={props.showTimeSelect ? true : false}
              showTimeSelectOnly={props.timeonly ? true : false}
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption=""
              showPopperArrow={false}
              minDate={props.minDate}
              maxDate={props.maxDate}
              disabled={props.disabled}
              filterTime={(time) => filterPassedTime(time, props.minDate)}
              ref={calendarRef}
              onKeyDown={props.onKeyDown}
              popperPlacement={props?.popperPlacement}
            />
            {!props.timeonly ? (
              <div className="calendar-icon" onClick={handleCalendarIconClick}>
                <Icon iconName="calendar2" iconSize={20} />
              </div>
            ) : (
              <div className="time-icon">
                <Icon iconName="Businessdetails03" iconSize={20} />
              </div>
            )}
            <div
              className={
                "d-flex " +
                ((props.isinvalid ? "justify-content-between" : "") ||
                  (!props.isinvalid ? "justify-content-end" : ""))
              }
            >
              {props.isinvalid && (
                <div className="invalid-msg">{props.invalidmsg}</div>
              )}{" "}
            </div>
          </div>
        );
      case "date-local":
        return (
          <div
            className={"form-group-input " + (props.class ? props.class : "")}
          >
            <input
              id={props.id}
              className={props.className + (props.isinvalid ? " invalid" : "")}
              name={props.name}
              style={props.style}
              type="date"
              placeholder={props.placeholder}
              defaultValue={props.defaultValue}
              value={props.value}
              disabled={props.disabled}
              onChange={props.onChange}
              onBlur={props.onBlur}
              readOnly={props.readonly}
              max={props.maxDate}
              min={props.minDate}
            />
          </div>
        );
      case "phone-number":
        return (
          <label
            name={props?.name}
            className={
              "form-group-phone " +
              (props.class ? props.class : "") +
              (props.required ? "required" : "")
            }
          >
            <PhoneInput
              country={lowercaseWord(env.baseCountry)}
              value={props.value}
              onChange={(event) => props.onChange(event)}
              onBlur={props.onBlur ? (event) => props.onBlur(event) : null}
              specialLabel={props.placeholder}
              countryCodeEditable={true}
              required={props.required}
              disabled={props.disabled}
            />
            {props.isinvalid && (
              <div className="invalid-msg">{props.invalidmsg}</div>
            )}
            {props.addition && (
              <div className="link" onClick={props.additionFunc}>
                {props.additionlabel}
              </div>
            )}
          </label>
        );
      // case "group-dropdown": return <InputGroup className={props.className}><div className="form-group-input "><input className="form-control" type={props.insideType} id={props.id} name={props.name} onChange={props.onChange} onBlur={props.onBlur} placeholder={props.placeholder} defaultValue={props.defaultValue} value={props.value} disabled={props.disabled} autoFocus={props.autoFocus} pattern={props.pattern} onClick={props.onClick} onFocus={props.onFocus} onKeyPress={props.onKeyPress} readOnly={props.readonly} />{props.formLabel && <label htmlFor={props.formLabel}>{props.formLabel} {props.required && <span>*</span>}</label>}</div><InputGroupButtonDropdown addonType="append" isOpen={props.isOpen} toggle={props.toggle}><DropdownToggle outline class={props.class}>{props.dropDownText}</DropdownToggle><DropdownMenu>{props.items.map(item => <DropdownItem onClick={props.itemClickHandler}>{item}</DropdownItem>)}</DropdownMenu></InputGroupButtonDropdown></InputGroup>;
      case "domain":
        return (
          <div className="form-group-input">
            <div className="form-group-domain">
              <input
                id={props.id}
                className={
                  props.className + (props.isinvalid ? " invalid" : "")
                }
                name={props.name}
                type={props.type}
                onChange={props.onChange}
                placeholder={props.placeholder}
                defaultValue={props.defaultValue}
                value={props.value}
                disabled={props.disabled}
                onKeyPress={props.onKeyPress}
                readOnly={props.readonly}
                autoComplete="off"
              />
              <span
                className={
                  "input-group-text " +
                  (props.addonClick ? "cursor-pointer" : "")
                }
                onClick={props.addonClick}
              >
                {props.addonText}
              </span>
              {props.formLabel && (
                <label htmlFor={props.formLabel}>
                  {props.formLabel}{" "}
                  {props.required && <span className="mt-3">*</span>}
                  {props.info && <Customtooltip item={props.infoData} />}{" "}
                </label>
              )}
            </div>
            {props.isinvalid && (
              <div className="invalid-msg">{props.invalidmsg}</div>
            )}
          </div>
        );
      case "email":
        return (
          <div className={"form-group-input form-group-email"}>
            <input
              id={props.id}
              className={props.className + (props.isinvalid ? " invalid" : "")}
              name={props.name}
              type={props.type}
              onChange={props.onChange}
              onBlur={props.onBlur}
              placeholder={props.placeholder}
              defaultValue={props.defaultValue}
              value={props.value}
              disabled={props.disabled}
              autoFocus={props.autoFocus}
              pattern="[^ @]*@[^ @]*"
              onClick={props.onClick}
              onFocus={props.onFocus}
              onKeyPress={props.onKeyPress}
              readOnly={props.readonly}
              maxLength="250"
              autoComplete="off"
            />
            {props.formLabel && (
              <label htmlFor={props.formLabel}>
                {props.formLabel} {props.required && <span>*</span>}{" "}
                {props.info && <Customtooltip item={props.infoData} />}{" "}
              </label>
            )}{" "}
            {props.email && (
              <div className="email-icon">
                <Icon iconName="mail-new" iconSize={22} />
              </div>
            )}{" "}
            <div
              className={
                "d-flex " +
                ((props.forgotpassword && props.isinvalid
                  ? "justify-content-between"
                  : "") ||
                  (props.forgotpassword || !props.isinvalid
                    ? "justify-content-end"
                    : ""))
              }
            >
              {props.isinvalid && (
                <div className="invalid-msg">{props.invalidmsg}</div>
              )}{" "}
            </div>{" "}
          </div>
        );
      default:
        return (
          <div
            className={
              "form-group-input " +
              (props.class ? props.class : "") +
              (props.type === "email" ? "form-group-email" : "")
            }
            style={props.style}
          >
            <input
              id={props.id}
              className={props.className + (props.isinvalid ? " invalid" : "")}
              name={props.name}
              type={props.type}
              onChange={props.onChange}
              onBlur={props.onBlur}
              placeholder={props.placeholder}
              defaultValue={props.defaultValue}
              value={props.value}
              disabled={props.disabled}
              autoFocus={props.autoFocus}
              pattern={
                props.type === "email"
                  ? "[^ @]*@[^ @]*"
                  : props.type === "number"
                  ? "[0-9]"
                  : props.pattern
              }
              onClick={props.onClick}
              onFocus={props.onFocus}
              onKeyPress={props.onKeyPress}
              readOnly={props.readonly}
              maxLength="250"
              min={props.type === "number" ? 0 : undefined}
              autoComplete="off"
            />
            {props.formLabel && (
              <label htmlFor={props.formLabel}>
                {props.formLabel} {props.required && <span>*</span>}{" "}
                {props.info && <Customtooltip item={props.infoData} />}{" "}
              </label>
            )}{" "}
            {props.passwordtoggle && (
              <div className="icon-toggle-eye" onClick={props.changeType}>
                {props.passwordtype === "password" ? (
                  <Icon iconName="hide-password" iconSize={22} />
                ) : (
                  <Icon iconName="show-password" iconSize={22} />
                )}
              </div>
            )}
            {props.search && (
              <div className="search-icon">
                <Icon iconName="search" />
              </div>
            )}
            {props.email && (
              <div className="email-icon">
                <Icon iconName="mail-new" iconSize={22} />
              </div>
            )}{" "}
            <div
              className={
                "d-flex " +
                ((props.forgotpassword && props.isinvalid
                  ? "justify-content-between"
                  : "") ||
                  (props.forgotpassword || !props.isinvalid
                    ? "justify-content-end"
                    : ""))
              }
            >
              {props.isinvalid && (
                <div className="invalid-msg">{props.invalidmsg}</div>
              )}{" "}
              {props.forgotpassword && (
                <Link className="label-right-bottom" to={props.labelto}>
                  {props.labeltoname}
                </Link>
              )}
            </div>{" "}
            {props.addition && (
              <div className="link" onClick={props.additionFunc}>
                {props.additionlabel}
              </div>
            )}
          </div>
        );
    }
  };
  return <React.Fragment>{InputTypeSwitch()}</React.Fragment>;
};
export default Input;
Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
};

